<template>
  <form @submit="updateToCreditcard" v-on:submit.prevent ref="form">
    <v-row no-gutters>
      <v-col cols="12" sm="6" class="mb-3">

        <v-text-field ref="name" autocomplete="off" dense label="Nome impresso no cartão" v-model="form.name"
          :error="!error.name.valid" :error-messages="getMessageError('name')" @input="validateField('name')" />

      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="6" class="mb-3">

        <v-text-field ref="creditcard" autocomplete="off" dense label="Número do Cartão" v-model="form.creditcard"
          :error="!error.creditcard.valid" :error-messages="getMessageError('creditcard')"
          @input="validateField('creditcard')" v-mask="'#### - #### - #### - ####'" :v-masked="true" />

        <icons-card :active="brand" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="6" sm="2" class="mr-2 mb-3">
        <v-text-field ref="expMonth" dense autocomplete="off" label="Validade - Mês" v-mask="'##'"
          v-model="form.expMonth" :error="!error.expMonth.valid" :error-messages="getMessageError('expMonth')"
          @input="validateField('expMonth')" />
      </v-col>
      <v-col cols="6" sm="2" class="mr-2 mb-3">
        <v-text-field ref="expYear" dense autocomplete="off" label="Validade - Ano" v-mask="'##'" v-model="form.expYear"
          :error="!error.expYear.valid" :error-messages="getMessageError('expYear')"
          @input="validateField('expYear')" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="1">
        <v-text-field ref="cvv" autocomplete="off" dense label="CVV" v-model="form.cvv" :error="!error.cvv.valid"
          :error-messages="getMessageError('cvv')" @input="validateField('cvv')" pattern="[0-9]{3,4}" v-mask="'####'" />
      </v-col>
    </v-row>


    <v-row justify="space-between">
      <v-col cols="12">
        <v-btn color="grey" class="white--text mr-3 mb-2" @click="cancel()" :disabled="loading">
          CANCELAR
        </v-btn>
        <v-btn color="success" class="white--text mb-2" type="submit" :loading="loading">
          TROCAR PARA CARTÃO
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>
<script>
import creditcard from '@src/services/creditcard'
import iconsCard from '@components/widget/iconsCard.vue'
import { TYPE_CREDITCARD } from '@/store/modules/paymentMethod'
export default {
  name: 'payment-creditcard',
  components: {
    iconsCard,
  },
  data() {
    return {
      loading: false,
      error: {
        name: {
          valid: true,
          isValid: (name) => !!name,
          errorMassang: 'Preencha corretamente',
        },
        creditcard: {
          valid: true,
          isValid: (value) => this.validateNumber(value),
          errorMassang: 'Preencha corretamente',
        },
        cvv: {
          valid: true,
          isValid: (creditcard, cvv) => this.validateCvv(creditcard, cvv),
          errorMassang: 'Preencha corretamente',
        },
        expMonth: {
          valid: true,
          isValid: (month, year) => this.validateExpirationByMonthAndYear({
            month,
            year,
          }),
          errorMassang: 'Preencha corretamente',
        },
        expYear: {
          valid: true,
          isValid: (month, year) => this.validateExpirationByMonthAndYear({
            month,
            year,
          }),
          errorMassang: 'Preencha corretamente',
        },
      },
      form: {
        name: '',
        creditcard: '',
        cvv: '',
        expMonth: '',
        expYear: '',
        firstName: '',
        lastName: '',
      },
    }
  },
  computed: {
    brand() {
      if (this.form.creditcard.length > 4) {
        return this.getBrandByCreditCardNumber(this.form.creditcard)
      }
      return null
    },
    firstName() {
      const [firstName] = this.form.name.trim().split(' ');
      return firstName || '';
    },
    lastName() {
      const nameParts = this.form.name.trim().split(' ');
      return nameParts.length > 1
        ? nameParts[nameParts.length - 1]
        : '';
    },
  },
  methods: {
    ...creditcard,
    getMessageError(field) {
      return !this.error[field].valid ? this.error[field].errorMassang : ''
    },
    validateField(field) {
      this.error[field].valid = true
    },
    creditcardIsValid() {
      this.error.name.valid = this.error.name.isValid(this.form.name);
      this.error.creditcard.valid = this.error.creditcard.isValid(this.form.creditcard);
      this.error.cvv.valid = this.error.cvv.isValid(this.form.creditcard, this.form.cvv);
      this.error.expMonth.valid = this.error.expMonth.isValid(this.form.expMonth, this.form.expYear);
      this.error.expYear.valid = this.error.expYear.isValid(this.form.expMonth, this.form.expYear);

      return (
        this.error.creditcard.valid &&
        this.error.cvv.valid &&
        this.error.expYear.valid &&
        this.error.expMonth.valid &&
        this.error.name.valid
      )
    },
    setErrorCardAll() {
      this.error.creditcard.valid = false
      this.error.cvv.valid = false
      this.error.expYear.valid = false
      this.error.expMonth.valid = false
      this.error.name.valid = false
    },
    getIuguCard() {
      return this.objectCreditCard({
        number: this.form.creditcard,
        month: this.form.expMonth,
        year: this.form.expYear,
        firstName: this.firstName,
        lastName: this.lastName,
        cvv: this.form.cvv,
      })
    },
    async updateToCreditcard(event) {
      event.preventDefault()
      this.loading = true
      try {
        if (!this.creditcardIsValid()) {
          this.loading = false
          return
        }
        const objCardIugu = this.getIuguCard()
        let iuguToken = await this.createCreditcardToken(objCardIugu)
        if (iuguToken.errors) {
          this.setErrorCardAll()
          throw new Error('Erro ao gerar o token da Iugu');
        }
        await this.$store.dispatch('paymentMethod/cahngePaymentMethod', {
          paymentType: TYPE_CREDITCARD,
          tokenIugu: iuguToken.id,
          creditCardLastNumbers: iuguToken.extra_info.display_number,
        })
        this.$store.commit('snackbarFlashMessage/setSuccessEdition')
        this.$emit('updated')

      } catch (error) {
        console.error('Erro ao processar o pagamento:', error);
      } finally {
        this.loading = false
      }
    },
    cancel() {
      this.form = {
        name: '',
        creditcard: '',
        cvv: '',
        expMonth: '',
        expYear: '',
        firstName: '',
        lastName: '',
      }
      this.error.creditcard.valid = true
      this.error.cvv.valid = true
      this.error.expYear.valid = true
      this.error.expMonth.valid = true
      this.error.name.valid = true
      this.$emit('cancel')
    },
  },
}
</script>
<style module lang="scss">
@import '@design';
</style>
